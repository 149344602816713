<template>
  <div
    class='header'
    :style="{backgroundImage:`url(${background})`}"
  >
    <div class="left">
      <p class="photo">
        <img
          src="@/assets/user.png"
          alt=""
        >
        <span>{{name}}</span>
      </p>
      <router-link
        class="menuItem"
        :to="{path:'/areastatistics'}"
      >
        基本信息
      </router-link>
      <router-link
        class="menuItem"
        :to="{path:'/imagerecognition'}"
        v-if="routerShow"
      >
        图像识别
      </router-link>
      <router-link
        class="menuItem"
        :to="{path:'/decision'}"
        v-if="routerShow"
      >
        决策分析
      </router-link>
      <router-link
        class="menuItem"
        :to="{path:'/video'}"
        v-if="!routerShow"
      >
        视频看板
      </router-link>
    </div>
    <div class="right">
      <router-link
        class="menuItem"
        :to="{path:'/video'}"
        v-if="routerShow"
      >
        视频看板
      </router-link>
      <router-link
        class="menuItem"
        :to="{path:'/equipment'}"
      >
        设备管理
      </router-link>
      <router-link
        class="menuItem"
        :to="{path:'/system'}"
      >
        系统管理
      </router-link>
      <p
        class="back"
        @click="logout"
      >
        <img
          src="@/assets/out.png"
          alt=""
        >
        <span>退出</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      routerShow: false,
      background: ''
    };
  },
  created () {
    this.routerShow = this.domainName === '庆元县' || this.domainName === '温州市' || this.domainName === '上城区' ? true : false
    this.background = require(`../../../assets/${this.acronym}_head.png`)
    // console.log(this.acronym)
  },
  mounted () {
  },
  computed: {
    ...mapGetters(["name", 'domainName', 'acronym']),
  },
  methods: {
    async logout () {
      this.$confirm('确定退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        lockScroll: false
      }).then(() => {
        this.$store.dispatch('FedLogOut').then(() => {
          location.href = '/login';
        })
      }).catch(() => { });
    }
  }
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 100px;
  padding: 0 60px 0 80px;
  box-sizing: border-box;
  background: url("../../../assets/qy_head.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  color: rgba(245, 245, 245, 0.7);
  font-size: 14px;
  .left,
  .right {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .menuItem {
      width: 129px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      margin-right: 20px;
      background: url("../../../assets/menu.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      color: rgba(245, 245, 245, 0.7);
    }
    .menuItem:hover {
      color: #ffffff;
    }
    // justify-content: space-around;
  }
  .photo,
  .back {
    display: flex;
    align-items: center;
    color: #30c6fe;
    cursor: pointer;
    span {
      margin: 0 20px 0 10px;
    }
  }
}
</style>
