<template>
  <div class='mainbody'>
    <Header />
    <!-- <header> -->
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from './components/header'
export default {
  components: { Header },
  data () {
    return {

    };
  },
  created () {
    this.$store.dispatch('getLocation')
    this.$store.dispatch('getScene')
    this.$store.dispatch('getAlgorithm')
  },
  mounted () {

  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
.mainbody {
  width: 100%;
  height: 100vh;
  background: url("../../assets/contentbg.png") no-repeat;
  background-size: 100% 100%;
  > .content {
    padding: 0 20px;
  }
}
</style>
